

export const getMenuLeaf = async (_path?:string, instance?:string) =>{
    const path = _path.replace(/^\//,"").replace(/%/g,"%25")

    try{
        const res = await tFetch(`/api/v3/get-menu?pathEncoded=${encodeURIComponent(path || '/')}&instance=${instance||''}`) 
        
        if(res.status == 200 && res.payload){
            return {
                ...res.payload,
                display: res.payload.display || res.payload.Display,
                children: (res.payload.children || res.payload.Children||[]).map((c)=>{
                    return {
                        ...c,
                        display: c.display || c.Display
                    }
                })
            }
        }else{
            console.log('getMenuLeaf err 01', res)
            return {
                id: '',
                name: '',
                display: '',
                children:[]
            }
        }
    }catch(e){
        console.log('getMenuLeaf err', e)
        return undefined
    }
}


const rootCache = {value:[] as  any}
export const getMenuRoots = async () => {
    if(rootCache.value.length > 0) return rootCache.value
    const res = await tFetch('/api/v3/get-menu')
    if(res.status == 200){
        rootCache.value = res.payload
        return res.payload
    }else{
        return []
    }
}